import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { NavbarWeb } from '../NavbarWeb/NavbarWeb';
import { NavBarWebSubWht } from '../NavBarWebSubWht/NavBarWebSubWht';
import styles from './styles.module.css';
import proPlan from '../../images/proPlan.png';
import bixPlan from '../../images/bizPlan.png';
import enterprisePlan from '../../images/enterprisePlan.png';



export const Plan = () => {
    const { id } = useParams();
    const [planType, setPlanType] = useState();
    const history = useHistory();
    const imgArray = { proPlan, bixPlan, enterprisePlan };
    const prices = { pro: '$1,000.00', biz: '$3,000.00', ent: '$10,000.00' };
    const [curPrice, setCurPrice] = useState();
    const [img, setImg] = useState();

    const [windowDW, setWindowDW] = useState(null);
    const [windowDH, setWindowDH] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);


  
    useEffect(() => {
      setWindowDW(window.innerWidth);
      setWindowDH(window.innerHeight);
    }, []);
    
    let vh = windowDH * 0.01;
    
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    console.log('*initial VH was set in css as::', vh);
    


    const getHeight = () => window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight;

  function useCurrentHeight() {
    let [height, setHeight] = useState(getHeight());

    useEffect(() => {
      let timeoutId = null;
      const resizeListener = () => {

        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => setHeight(getHeight(), 50));
      };

      window.addEventListener('resize', resizeListener, false);
      //  window.addEventListener('scroll', resizeListener, false);

      return () => {
        window.removeEventListener('resize', resizeListener, false);
        //  window.removeEventListener('scroll', resizeListener, false);

      }
    }, [])
    console.log('resize function ran');
    return height;
  }

  let h = useCurrentHeight();
  console.log(h);



  useEffect(() => {
    let vh = h * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    console.log('vh', vh, 'h:', h);
    setWindowDH(h);
    console.log('*after resize Listener VH was set in css as::', vh);

  }, [h]);


  console.log('windowDH', windowDH);





    const capitalizeFirst = id => {
        return id?.toString().charAt(0).toUpperCase() + id?.toString().slice(1);
    }

    useEffect(() => {
        if (id) {
            setPlanType(capitalizeFirst(id));
            if (id == 'professional') {
                setImg(imgArray.proPlan);
                setCurPrice(prices.pro);
            }
            if (id == 'business') {
                setImg(imgArray.bixPlan);
                setCurPrice(prices.biz);
            }
            if (id == 'enterprise') {
                setImg(imgArray.enterprisePlan);
                setCurPrice(prices.ent);
            }
        }


    }, [id]);

    const Pro = () => {

        return (
            <div className={styles.column}>

                <div className={styles.planT}>
                    Website {planType} Plan
                </div>
                <div className={styles.txtP}
                >Popular for portfolios or basic web pages for personal use and growing your brand getting it off the ground. Get on the internet with your own custom project designed and developed by the Lune Vista Web team with continued management by Lune Vista after implementation. Request now for a free consultation so we can learn more about your needs and create a custom plan just for you.
                </div>
                <div className={styles.txt}
                >  Custom Domain
                </div>
                <div className={styles.txt}
                > Simple and basic design
                </div>
                <div className={styles.txt}
                > Unlimited bandwidth
                </div>
                <div className={styles.txt}
                > 99.9% Uptime
                </div>
                <div className={styles.txt}
                >  Responsive CSS
                </div>
                <div className={styles.txt}
                >  SSL certificate
                </div>

                <div className={styles.subCont}>

                    <div className={styles.rBtn} onClick={() => {history.push(`/request/${id}/web`)}}>Request Free Consultation Now</div>

                </div>
            </div>

        )
    }

    const Biz = () => {

        return (
            <div className={styles.column}>

                <div className={styles.planT}>
                    Website {planType} Plan
                </div>
                <div className={styles.txtP}
                >Geared more towards projects in need of advanced web features like online payments, booking, and events. Get a customized plan designed and developed by the Lune Vista Web team using advanced Application Program Interfaces(API). Request now for a free consultation for us to learn more about your exact needs.
                </div>
                <div className={styles.txt}
                >  Custom Domain
                </div>
                <div className={styles.txt}
                > Complex and unique design
                </div>
                <div className={styles.txt}
                > Unlimited bandwidth
                </div>
                <div className={styles.txt}
                > 99.9% Uptime
                </div>
                <div className={styles.txt}
                >  Responsive CSS
                </div>
                <div className={styles.txt}
                >  SSL certificate
                </div>
                <div className={styles.txt}
                >  Advanced functionality
                </div>

                <div className={styles.subCont}>

                    <div className={styles.rBtn} onClick={()=> {history.push(`/request/${id}/web`)}} >Request Free Consultation Now</div>

                </div>
            </div>

        )
    }
    const Ent = () => {

        return (
            <div className={styles.column}>

                <div className={styles.planT}>
                    Website {planType} Plan
                </div>
                <div className={styles.txtP}
                >For large scale projects requiring a tailored solution with advanced web applications. Get your customized web plan designed and developed by the Lune Vista Web team with continued management by Lune Vista after implementation. Request now for a free consultation for us to learn more about your exact needs.
                </div>
                <div className={styles.txt}
                >  Custom Domain
                </div>
                <div className={styles.txt}
                > Completely design
                </div>
                <div className={styles.txt}
                > Unlimited bandwidth
                </div>
                <div className={styles.txt}
                > 99.9% Uptime
                </div>
                <div className={styles.txt}
                >  Responsive CSS
                </div>
                <div className={styles.txt}
                >  SSL certificate
                </div>
                <div className={styles.txt}
                >  Advanced functionality
                </div>

                <div className={styles.subCont}>

                    <div className={styles.rBtn} onClick={()=> {history.push(`/request/${id}/web`)}}>Request Free Consultation Now</div>

                </div>
            </div>

        )
    }

    const Plan = () => {
        if (id) {
            // setPlanType(capitalizeFirst(id));
            if (id == 'professional') {
                return <Pro />;
            }
            if (id == 'business') {
                return <Biz />;
            }
            if (id == 'enterprise') {
                return <Ent />;
            }
            if (id == 'logo-design') {
                return <Ent />;
            }
            if (id == 'identity-package') {
                return <Ent />;
            }
            if (id == 'brand-package') {
                return <Ent />;
            }
        }

    }
    const PCard = () => {
        return (
            <div className={styles.column}>
                <img src={proPlan} />
                <div className={styles.cardT}>Professional Plan</div>

                <div className={styles.colCont}>
                    <div className={styles.start}>Starting at</div>
                    <div className={styles.price}>$1,000.00</div>
                    <div className={styles.rBtn3} onClick={() => {history.push('/plan/professional')}}
                    >Learn More</div>
                </div>

            </div>

        )
    }

    const BCard = () => {
        return (
            <div className={styles.column}>
                <img src={bixPlan} />
                <div className={styles.cardT}>Business Plan</div>

                <div className={styles.colCont}>
                    <div className={styles.start}>Starting at</div>
                    <div className={styles.price}>$3,000.00</div>
                    <div className={styles.rBtn3} onClick={() => {history.push('/plan/business')}}
                    >Learn More</div>
                </div>

            </div>

        )
    }
    const ECard = () => {
        return (
            <div className={styles.column}>
                <img src={enterprisePlan} />
                <div className={styles.cardT}>Enterprise Plan</div>

                <div className={styles.colCont}>
                    <div className={styles.start}>Starting at</div>
                    <div className={styles.price}>$10,000.00</div>
                    <div className={styles.rBtn3} onClick={() => {history.push('/plan/enterprise')}}
                    >Learn More</div>
                </div>

            </div>

        )
    }

    const Compare = () => {

        if (id) {
            // setPlanType(capitalizeFirst(id));
            if (id == 'professional') {
                return(
                    <div className={styles.splitRow3}>
                        <BCard/>
                        <ECard/>
                    </div>
                );
            }
            if (id == 'business') {
                return(
                    <div className={styles.splitRow3}>
                        <PCard/>
                        <ECard/>
                    </div>
                );
            }
            if (id == 'enterprise') {
                return(
                    <div className={styles.splitRow3}>
                        <PCard/>
                        <BCard/>
                    </div>
                );
            }
        }
    }


    return (
        <div className={styles.main}>
            <NavbarWeb />
            <NavBarWebSubWht />
            <div className={styles.page}>

                <div className={styles.tOne}>

                    <div className={styles.splitRow2}>
                        <div className={styles.column}>
                            <img src={img} />
                            <div className={styles.subTxt}
                                onClick={() => { history.push('/web-plans') }}
                            >
                                Compare all Website pricing plans
                            </div>
                        </div>

                        <Plan />

                    </div>

                </div>

                <div className={styles.tOne}>

                    <div className={styles.planT2}>Compare Website Plans</div>
                    <div className={styles.splitRow3}>
                        <Compare/>
                    </div>

                </div>
                <div className={styles.tFoot}>
                <div className={styles.txtCont}>
                <div className={styles.txt2}>
                    Find out where we can help.

                </div>
                <div className={styles.subTxt2} onClick={() => history.push('/contact')}>
                    Request Free Consultation Now
                    
                </div>
            </div>

                </div>


                <div className={styles.stickFooter}>
                    <div className={styles.footRow}>
                        <div className={styles.footTitle}>{planType} Plan</div>
                        <div className={styles.rowCont}>
                            <div className={styles.colCont}>
                                <div className={styles.start}>Starting at</div>
                                <div className={styles.price}>{curPrice}</div>
                            </div>

                            <div className={styles.rBtn2} onClick={() => {history.push(`/request/${id}/web`)}}>Request Now</div>
                        </div>

                    </div>
                </div>


            </div>
        </div>
    )
}