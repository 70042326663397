import styles from './styles.module.css';


export const LogoDesign = () => {

    return(
        <div className={styles.main}>

        </div>
    )
}